/* Global Styles */
body {
    padding: 0;
    margin: 0;
    background-image: url(/public/img/1.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    font-family: Arial, sans-serif;
    font-size: 16px; /* Base font-size */
}

/* Centering content */
main {
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.897);
}

/* Input styles */
.input {
    padding: 10px;
    border: 1px solid #ccc;
    margin-top: 10px;
    width: 180px;
}

/* Button styles */
.button {
    margin-top: 15px;
    padding: 10px;
    width: 200px;
    border: none;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.button:hover {
    background-color: #0056b3;
}

.input:focus {
    outline: none;
    border-color: #007BFF;
}