body{
    margin: 0;
    padding: 0;
}

#dashboard{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(255, 255, 255);
}

#ulDash{
    display: flex;
    flex-direction: column;
    padding: 0;
}

#liDash{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

#searchDash{
    width: 250px;
    height: 30px;
}

#searchBar{
    margin-top: 25px;
    padding: 10px;
    margin-bottom: 20px;
    width: 300px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.534);
}

#logout{
    border: 0px;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    padding-top: 25px;
}

#logout:hover{
    opacity: 75%;
}

#logout svg {
    transition: transform 0.2s ease;
}

#nav{
    display: flex;
    width: 100%;
    justify-content: center;
}

#profile{
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    width: auto;
    height: 150px;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
}

#sName{
    margin: 0;
}